import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "view-as-banner__content" };
import Eye from '@icons/eye.svg';
import { computed } from 'vue';
import { csrfToken } from '@ts/Util/security';
import { post } from '@ts/Util/http';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ViewAs',
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        viewAsClient: {
            type: Boolean,
            required: true,
        },
        action: {
            type: String,
            default: '',
        },
        t: {
            type: Object,
            default: () => ({})
        },
        absolute: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['view-as:clicked'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const message = computed(() => {
            return props.viewAsClient ? props.t.yourself : props.t.student;
        });
        const cta = computed(() => {
            return props.viewAsClient ? props.t.viewAsYourself : props.t.viewAsStudent;
        });
        const viewAs = async () => {
            if (!props.action) {
                emit('view-as:clicked');
                return;
            }
            const viewAsClient = !props.viewAsClient;
            const response = await post(props.action, csrfToken(), {
                viewAsClient,
            });
            if (response.ok) {
                const url = new URL(location.href);
                viewAsClient ? url.searchParams.set('client', '') : url.searchParams.delete('client');
                window.location.href = url.href.replace('=', '');
            }
        };
        return (_ctx, _cache) => {
            return (__props.show)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["view-as-banner f-inter", { absolute: __props.absolute }])
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_unref(Eye)),
                        _createElementVNode("span", null, _toDisplayString(message.value), 1)
                    ]),
                    _createElementVNode("a", {
                        href: "#",
                        class: "f-inter",
                        onClick: _withModifiers(viewAs, ["prevent"])
                    }, _toDisplayString(cta.value), 1)
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
