import { defineComponent, reactive, computed } from 'vue';
import Modal from "@components/Core/Modal.vue";
import { validateTranslations } from "@ts/Util/i18n";
import Rating from "@modules/Rating/Rating.vue";
import { interpolate } from "@ts/Util/text";
import HorizontalSeparator from "@components/Core/HorizontalSeparator.vue";
import TextArea from "@components/Core/TextArea.vue";
import ZButton from "@components/Core/ZButton.vue";
import { useSafariMobileKeyboard } from "@composables/safariMobileKeyboard";
const localT = {
    rateYourExperience: 'Rate your experience with {0}',
    instructorRating: 'Instructor rating',
    ratingSubmitted: 'Rating submitted',
    reviewHeader: 'Leave a Review',
    reviewBody: 'Share a review about this instructor.',
    reviewPlaceHolder: 'How was their class, their coaching style?',
    ratingSubmittedOn: 'Rating submitted {0} at {1}',
    skipForNow: 'Skip for now',
    submit: 'Submit',
    cancel: 'Cancel',
    edit: 'Edit',
};
export default defineComponent({
    name: 'RateInstructor',
    components: {
        Modal,
        ZButton,
        TextArea,
        HorizontalSeparator,
        Rating,
    },
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: (value) => validateTranslations(value, localT),
        },
        show: {
            type: Boolean,
            default: false
        },
        instructorPid: {
            type: String,
            required: true
        },
        instructorName: {
            type: String,
            required: true,
        },
        rating: {
            type: [Number, String],
            default: null,
            validate: (value) => {
                return value === null || (+value > 0 && +value < 6);
            }
        },
        currency: {
            type: String,
            default: 'USD',
        },
        tipOptions: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            default: 'rate',
            validate: (value) => {
                return ['rate', 'tip'].includes(value);
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        ratingSubmitted: {
            type: String,
            default: null
        }
    },
    emits: [
        'rate-instructor:rate',
        'rate-instructor:tip',
        'closed',
        'rate-instructor:edit',
    ],
    setup(props, { emit }) {
        const state = reactive({
            rating: Boolean(props.rating) ? Number(props.rating) : 0,
            comment: '',
            isSubmitted: Boolean(props.ratingSubmitted)
        });
        const showReview = computed(() => {
            return !!state.rating && !state.isSubmitted;
        });
        const title = computed(() => {
            return interpolate(props.t.rateYourExperience, [props.instructorName]);
        });
        const humanizedRatingDate = computed(() => {
            if (!props.ratingSubmitted) {
                return '';
            }
            const dateOptions = {
                month: 'short',
                day: 'numeric',
            };
            const timeOptions = {
                hour: 'numeric',
                minute: 'numeric',
            };
            const date = new Date(props.ratingSubmitted);
            const dateFormatted = new Intl.DateTimeFormat(window.navigator.language, dateOptions)
                .format(date);
            const timeFormatted = new Intl.DateTimeFormat(window.navigator.language, timeOptions)
                .format(date);
            return interpolate(props.t.ratingSubmittedOn, [dateFormatted, timeFormatted]);
        });
        const handleRatingSelect = (rating) => {
            state.rating = rating;
        };
        const { scrollTextIntoView } = useSafariMobileKeyboard();
        return {
            state,
            title,
            showReview,
            handleRatingSelect,
            humanizedRatingDate,
            storeRating() {
                emit('rate-instructor:rate', {
                    rating: state.rating,
                    instructorPID: props.instructorPid,
                    comment: state.comment,
                    date: new Date(),
                });
            },
            edit() {
                state.rating = 0;
                state.isSubmitted = false;
                emit('rate-instructor:edit');
            },
            scrollTextIntoView,
        };
    },
});
