import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "rating" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_RateInstructor = _resolveComponent("RateInstructor");
    const _component_Toast = _resolveComponent("Toast");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.show)
            ? (_openBlock(), _createBlock(_component_RateInstructor, {
                key: 0,
                t: _ctx.t,
                show: _ctx.show,
                "instructor-name": _ctx.instructorName,
                "instructor-pid": _ctx.instructorPid,
                rating: _ctx.state.rating,
                "rating-submitted": _ctx.state.ratingSubmitted,
                mode: _ctx.state.mode,
                disabled: _ctx.state.disabled,
                "tip-options": _ctx.tipOptions,
                onClosed: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:show', false))),
                "onRateInstructor:rate": _ctx.storeRating,
                "onRateInstructor:edit": _cache[1] || (_cache[1] = ($event) => (_ctx.state.mode = 'rate'))
            }, null, 8, ["t", "show", "instructor-name", "instructor-pid", "rating", "rating-submitted", "mode", "disabled", "tip-options", "onRateInstructor:rate"]))
            : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.toastQueue, (toast, i) => {
            return (_openBlock(), _createBlock(_component_Toast, {
                key: i,
                style: {
                    'zIndex': 9999999
                },
                type: toast.type,
                message: toast.message,
                position: toast.position,
                timeout: 3000,
                "user-dismissable": false,
                "is-visible": Boolean(toast.message),
                "bottom-left": ""
            }, null, 8, ["type", "message", "position", "is-visible"]));
        }), 128))
    ]));
}
