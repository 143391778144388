import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "rating" };
import { computed } from 'vue';
import StarSvg from '@bx-icons/regular/bx-star.svg';
import StarFilledSvg from '@bx-icons/solid/bxs-star.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Rating',
    props: {
        score: {
            type: Number,
            required: true
        },
        maxScore: {
            type: Number,
            default: 5
        }
    },
    setup(__props) {
        const props = __props;
        const filled = computed(() => {
            return Math.floor(props.score);
        });
        const empty = computed(() => {
            return props.maxScore - filled.value;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filled.value, (n) => {
                    return (_openBlock(), _createBlock(_unref(StarFilledSvg), {
                        key: `filled-${n}`,
                        class: "rating-star"
                    }));
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(empty.value, (n) => {
                    return (_openBlock(), _createBlock(_unref(StarSvg), {
                        key: `empty-${n}`,
                        class: "rating-star"
                    }));
                }), 128))
            ]));
        };
    }
});
