import FacebookIcon from '@bx-icons/logos/bxl-facebook-circle.svg';
import LinkedinIcon from '@bx-icons/logos/bxl-linkedin.svg';
import InstagramIcon from '@bx-icons/logos/bxl-instagram-alt.svg';
import TiktokIcon from '@bx-icons/logos/bxl-tiktok.svg';
import SpotifyIcon from '@bx-icons/logos/bxl-spotify.svg';
import { computed, defineComponent, ref } from 'vue';
const localSocialLinks = {
    facebook: '',
    linkedin: '',
    instagram: '',
    tiktok: '',
    spotify: ''
};
export default defineComponent({
    name: 'SocialLinks',
    components: {
        FacebookIcon,
        LinkedinIcon,
        InstagramIcon,
        TiktokIcon,
        SpotifyIcon
    },
    props: {
        socialLinks: {
            type: Object,
            default: () => localSocialLinks,
            validator: (supplied) => Object.keys(localSocialLinks).every(key => key in supplied)
        },
        size: {
            type: String,
            default: 'normal',
        }
    },
    setup(props, ctx) {
        let parsedSocialLinks = ref(props.socialLinks).value;
        const socialBaseUrls = {
            facebook: 'facebook.com',
            instagram: 'instagram.com',
            spotify: 'open.spotify.com/user',
            tiktok: 'tiktok.com',
            linkedin: 'linkedin.com/in',
        };
        for (const key in socialBaseUrls) {
            if (parsedSocialLinks.hasOwnProperty(key) && parsedSocialLinks[key].length) {
                // Removing: leading slashes AND any double slashes except ://
                parsedSocialLinks[key] = parsedSocialLinks[key].replace(/^\/+/, '').replace(/:\/+/gm, '://');
                let socialLink = parsedSocialLinks[key].replace(/^https?:\/\//, '');
                if (!socialLink.includes(socialBaseUrls[key])) {
                    socialLink = `${socialBaseUrls[key]}/${parsedSocialLinks[key]}`;
                }
                parsedSocialLinks[key] = `https://${socialLink}`;
            }
        }
        const classes = computed(() => {
            return {
                'social-link--mobile-large': props.size === 'mobile-large'
            };
        });
        return {
            parsedSocialLinks,
            classes,
        };
    },
});
