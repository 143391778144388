import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "name", "value", "disabled"];
const _hoisted_3 = {
    class: /*@__PURE__*/ _normalizeClass({ checkbox: true })
};
const _hoisted_4 = { key: 0 };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { key: 1 };
const _hoisted_7 = {
    key: 0,
    class: "description__text"
};
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { key: 1 };
const _hoisted_10 = {
    key: 0,
    class: "description__text"
};
const _hoisted_11 = ["innerHTML"];
import { computed, ref, reactive, watch } from 'vue';
import { v4 as uuid } from 'uuid';
import CheckSvg from '@bx-icons/regular/bx-check.svg';
import MinusSvg from '@bx-icons/regular/bx-minus.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FormCheckbox',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: false,
            default: 'on'
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Used by vue to bind the checkbox check state to `v-model` set on the component.
         */
        modelValue: {
            type: [Boolean, (Array)],
            default: false,
        },
        isTableHeader: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'light',
            validator: (value) => {
                // You can add more modes types here
                return [
                    'light',
                    'dark',
                ].includes(value);
            },
        },
        boldItem: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: ''
        },
        labelHasLink: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:model-value'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const id = uuid();
        const focused = ref(false);
        const emit = __emit;
        const localValueState = computed({
            get() {
                return props.modelValue;
            },
            set(newValue) {
                emit('update:model-value', newValue);
            }
        });
        const isChecked = computed(() => {
            return Array.isArray(localValueState.value) ?
                localValueState.value.includes(props.value) :
                localValueState.value;
        });
        const focus = () => {
            focused.value = true;
        };
        const blur = () => {
            focused.value = false;
        };
        const onInvalid = (event) => {
            state.hasError = true;
        };
        const state = reactive({
            hasError: false
        });
        watch(() => props.error, () => {
            state.hasError = props.error !== '';
        }, { immediate: true });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    control_error: __props.error,
                    label_has_link: __props.labelHasLink
                })
            }, [
                _createElementVNode("label", { for: _unref(id) }, [
                    _createElementVNode("span", {
                        class: _normalizeClass({
                            focus_container: true,
                            focus_container_focused: focused.value,
                            focus_container_checked: isChecked.value
                        })
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                checkbox__control: true,
                                control_checked: isChecked.value,
                                control_focused: focused.value,
                                input_disabled: __props.disabled
                            })
                        }, [
                            _withDirectives(_createElementVNode("input", {
                                id: _unref(id),
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((localValueState).value = $event)),
                                name: __props.name,
                                value: __props.value,
                                type: "checkbox",
                                disabled: __props.disabled,
                                class: "input",
                                onFocus: focus,
                                onBlur: blur,
                                onInvalid: _cache[1] || (_cache[1] = ($event) => (onInvalid($event)))
                            }, null, 40, _hoisted_2), [
                                [_vModelCheckbox, localValueState.value]
                            ]),
                            _createElementVNode("span", _hoisted_3, [
                                _createVNode(_unref(CheckSvg), {
                                    "aria-hidden": "true",
                                    focusable: "false",
                                    class: _normalizeClass({ box: true, checked: isChecked.value, disabled: __props.disabled })
                                }, null, 8, ["class"]),
                                _createVNode(_unref(MinusSvg), {
                                    "aria-hidden": "true",
                                    focusable: "false",
                                    class: _normalizeClass({ box: true, minus: true })
                                })
                            ])
                        ], 2)
                    ], 2),
                    (!!_ctx.$slots?.label && !__props.labelHasLink)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                            _renderSlot(_ctx.$slots, "label")
                        ]))
                        : (!__props.labelHasLink)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass({
                                    label__text: true,
                                    [__props.mode]: true,
                                    'label__text--checked': isChecked.value,
                                    'label__table-header': __props.isTableHeader
                                })
                            }, [
                                (!__props.description && !__props.boldItem)
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        innerHTML: __props.label
                                    }, null, 8, _hoisted_5))
                                    : _createCommentVNode("", true),
                                (__props.description || __props.boldItem)
                                    ? (_openBlock(), _createElementBlock("b", _hoisted_6, _toDisplayString(__props.label), 1))
                                    : _createCommentVNode("", true),
                                _renderSlot(_ctx.$slots, "description", {}, () => [
                                    (__props.description)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(__props.description), 1))
                                        : _createCommentVNode("", true)
                                ])
                            ], 2))
                            : _createCommentVNode("", true)
                ], 8, _hoisted_1),
                (!!_ctx.$slots?.label && __props.labelHasLink)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass({ is_label_with_link: __props.labelHasLink })
                    }, [
                        _renderSlot(_ctx.$slots, "label")
                    ], 2))
                    : _createCommentVNode("", true),
                (__props.labelHasLink)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: _normalizeClass({
                            label__text: true,
                            [__props.mode]: true,
                            'label__table-header': __props.isTableHeader
                        })
                    }, [
                        (!__props.description && !__props.boldItem)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(__props.label), 1))
                            : _createCommentVNode("", true),
                        (__props.description || __props.boldItem)
                            ? (_openBlock(), _createElementBlock("b", _hoisted_9, _toDisplayString(__props.label), 1))
                            : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "description", {}, () => [
                            (__props.description)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(__props.description), 1))
                                : _createCommentVNode("", true)
                        ])
                    ], 2))
                    : _createCommentVNode("", true),
                (state.hasError)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        class: "control_error__message",
                        innerHTML: __props.error
                    }, null, 8, _hoisted_11))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
