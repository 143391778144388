import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "instructor-section"
};
const _hoisted_2 = { class: "instructor-section__header" };
const _hoisted_3 = { class: "instructor-section__title f-inter" };
const _hoisted_4 = ["href"];
const _hoisted_5 = {
    key: 1,
    class: "instructor-section__filters"
};
const _hoisted_6 = { class: "class-list__filter-trigger" };
const _hoisted_7 = {
    key: 0,
    class: "class-list__filter-count z-b5"
};
const _hoisted_8 = {
    key: 0,
    class: "class-list__filter-count z-b5"
};
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { class: "class-list" };
const _hoisted_11 = { key: 1 };
const _hoisted_12 = { class: "no-classes-posted f-inter" };
const _hoisted_13 = { key: 2 };
const _hoisted_14 = { class: "no-classes-posted f-inter" };
const _hoisted_15 = ["href"];
const _hoisted_16 = {
    key: 3,
    class: "class-list__show-more"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditIcon = _resolveComponent("EditIcon");
    const _component_CalendarIcon = _resolveComponent("CalendarIcon");
    const _component_DatePicker = _resolveComponent("DatePicker");
    const _component_FilterIcon = _resolveComponent("FilterIcon");
    const _component_ClassElement = _resolveComponent("ClassElement");
    const _component_ClassFilterModal = _resolveComponent("ClassFilterModal");
    return (_ctx.editMode || _ctx.classes?.length)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.t.title), 1),
                (_ctx.editMode)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: _ctx.liveClassesAction,
                        class: "edit-icon"
                    }, [
                        _createVNode(_component_EditIcon)
                    ], 8, _hoisted_4))
                    : _createCommentVNode("", true),
                ((!_ctx.editMode || _ctx.classes?.length) && !_ctx.hideFilters)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, [
                            (_ctx.dateFilter)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 1 "))
                                : _createCommentVNode("", true),
                            _createVNode(_component_DatePicker, {
                                modelValue: _ctx.dateFilter,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.dateFilter) = $event)),
                                class: "class-list__filter-datepicker"
                            }, {
                                icon: _withCtx(() => [
                                    _createVNode(_component_CalendarIcon, { class: "class-list__filter-icon" })
                                ]),
                                _: 1
                            }, 8, ["modelValue"])
                        ]),
                        _createElementVNode("a", {
                            class: "class-list__filter-trigger",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (_ctx.showFilters = true), ["prevent"]))
                        }, [
                            (_ctx.filterCount > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.filterCount), 1))
                                : _createCommentVNode("", true),
                            _createVNode(_component_FilterIcon, { class: "class-list__filter-icon" })
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ]),
            (_ctx.visibleClasses?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleClasses, (classItem) => {
                            return (_openBlock(), _createBlock(_component_ClassElement, {
                                key: classItem.id,
                                "class-item": classItem,
                                specialties: _ctx.specialties,
                                t: _ctx.t,
                                "onNoDirectPaymentModal:open": _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('no-direct-payment-modal:open', $event)))
                            }, null, 8, ["class-item", "specialties", "t"]));
                        }), 128))
                    ])
                ]))
                : (_ctx.classes?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                            _createTextVNode(_toDisplayString(_ctx.t.noClassesAvailable) + " ", 1),
                            _createElementVNode("a", {
                                class: "z-b3 f-inter",
                                onClick: _cache[3] || (_cache[3] = _withModifiers(($event) => (_ctx.clearFilters()), ["prevent"]))
                            }, _toDisplayString(_ctx.t.viewFullSchedule), 1)
                        ])
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                            _createTextVNode(_toDisplayString(_ctx.t.noClassesPosted) + " ", 1),
                            _createElementVNode("a", {
                                class: "z-b3 f-inter",
                                href: _ctx.liveClassesAction
                            }, _toDisplayString(_ctx.t.add), 9, _hoisted_15)
                        ])
                    ])),
            (_ctx.filteredClasses.length > _ctx.visibleRecords)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("a", {
                        class: "f-inter",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(($event) => (_ctx.visibleRecords += _ctx.pageSize), ["prevent"]))
                    }, _toDisplayString(_ctx.t.showMore), 1)
                ]))
                : _createCommentVNode("", true),
            _createVNode(_component_ClassFilterModal, {
                t: _ctx.t,
                show: _ctx.showFilters,
                specialties: _ctx.specialties,
                "available-filters": _ctx.availableFilters,
                onApply: _cache[5] || (_cache[5] = (filters) => _ctx.selectedFilters = filters),
                onClosed: _cache[6] || (_cache[6] = ($event) => (_ctx.showFilters = false))
            }, null, 8, ["t", "show", "specialties", "available-filters"])
        ]))
        : _createCommentVNode("", true);
}
