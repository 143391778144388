import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "instructor-section__title--redesign z-h1"
};
const _hoisted_2 = {
    key: 1,
    class: "instructor-section__title f-inter"
};
const _hoisted_3 = ["href"];
const _hoisted_4 = {
    key: 0,
    class: "break--redesign"
};
const _hoisted_5 = { class: "carousel-items" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { key: 1 };
const _hoisted_8 = { class: "no-classpacks-posted z-b3 f-inter" };
const _hoisted_9 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditIcon = _resolveComponent("EditIcon");
    const _component_ClassPacksItem = _resolveComponent("ClassPacksItem");
    const _component_carousel = _resolveComponent("carousel");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass({ 'instructor-section__bg--redesign': _ctx.enableRedesign && !_ctx.memberView,
            'instructor-section__bg--redesign-edit': _ctx.enableRedesign && _ctx.memberView })
    }, [
        (_ctx.editMode || _ctx.packs?.length)
            ? (_openBlock(), _createElementBlock("section", {
                key: 0,
                class: _normalizeClass(["instructor-section", { 'instructor-section--redesign': _ctx.enableRedesign }])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["instructor-classpack-title", { 'instructor-classpack-title--redesign': _ctx.enableRedesign && !_ctx.editMode,
                            'instructor-classpack-title--redesign-edit': _ctx.enableRedesign && _ctx.editMode }])
                }, [
                    (_ctx.enableRedesign)
                        ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.t.title), 1))
                        : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.t.title), 1)),
                    (_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            href: _ctx.classPacksAction,
                            class: "edit-icon"
                        }, [
                            _createVNode(_component_EditIcon, {
                                class: _normalizeClass({ 'edit-icon--redesign': _ctx.enableRedesign })
                            }, null, 8, ["class"])
                        ], 8, _hoisted_3))
                        : _createCommentVNode("", true)
                ], 2),
                (_ctx.enableRedesign && !_ctx.editMode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                    : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(["class-packs-listing", { 'class-packs-listing--redesign': _ctx.enableRedesign, 'few-items': _ctx.packs?.length < 3 }])
                }, [
                    _createElementVNode("div", _hoisted_5, [
                        (_ctx.packs?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({ 'carousel-items--redesign': _ctx.enableRedesign, 'carousel-items': !_ctx.enableRedesign })
                                }, [
                                    _createVNode(_component_carousel, {
                                        class: _normalizeClass({ 'safari': _ctx.checkBrowser() }),
                                        inline: "",
                                        "arrows-top": "",
                                        "enable-redesign": _ctx.enableRedesign
                                    }, _createSlots({ _: 2 }, [
                                        _renderList(_ctx.packs, (data, index) => {
                                            return {
                                                name: `slide${index}`,
                                                fn: _withCtx(() => [
                                                    (_openBlock(), _createBlock(_component_ClassPacksItem, {
                                                        key: data.id,
                                                        t: _ctx.t,
                                                        data: data,
                                                        subtitle: _ctx.packageSubtitle(data.price, data.class_quantity, data.price_currency),
                                                        and: _ctx.and
                                                    }, null, 8, ["t", "data", "subtitle", "and"]))
                                                ])
                                            };
                                        })
                                    ]), 1032, ["class", "enable-redesign"])
                                ], 2)
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createTextVNode(_toDisplayString(_ctx.t.noClassPacksPosted) + " ", 1),
                                    _createElementVNode("a", {
                                        class: "z-b3 f-inter",
                                        href: _ctx.classPacksAction
                                    }, _toDisplayString(_ctx.t.add), 9, _hoisted_9)
                                ])
                            ]))
                    ])
                ], 2)
            ], 2))
            : _createCommentVNode("", true)
    ], 2));
}
