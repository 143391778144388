import { defineComponent, computed } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import ZButton from '@components/Core/ZButton.vue';
import { theme, ThemeType } from "@ts/Util/theme";
import { formatCurrencyStr } from "@ts/Util/currency";
import { currentUserLocale } from '@ts/Util/i18n';
const translationDefaults = {
    buy: "Buy",
};
export default defineComponent({
    name: 'VODItem',
    components: {
        ZButton
    },
    props: {
        t: {
            type: Object,
            default: () => translationDefaults,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true,
        },
        photo: {
            type: String,
            required: true,
        },
        duration: {
            type: String,
            required: true,
        },
        price: {
            type: String,
            required: true,
        },
        currency: {
            type: String,
            required: true,
        },
        specialties: {
            type: Object,
            default: () => { }
        },
        allSpecialties: {
            type: Object,
            default: () => { }
        },
        detailsUrl: {
            type: String,
            default: '',
        },
        buyUrl: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const videoDuration = computed(() => {
            const hours = (parseInt(props.duration) / (60 * 60));
            const minutes = ((hours - Math.floor(hours)) * 60);
            const seconds = ((minutes - Math.floor(minutes)) * 60);
            const hoursF = Math.floor(hours);
            const minutesF = Math.floor(minutes);
            const secondsF = Math.floor(seconds);
            return `${hoursF}:${('00' + minutesF).substr(-2)}:${('00' + secondsF).substr(-2)}`;
        });
        const numericPrice = parseFloat(props.price);
        const formattedPrice = computed(() => {
            return formatCurrencyStr(numericPrice || 0, props.currency, currentUserLocale(), true);
        });
        const buttonThemed = theme.value === ThemeType.Zumba ? 'rogue' : 'love';
        return {
            videoDuration,
            formattedPrice,
            buttonThemed
        };
    }
});
