import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    ref: "carousel",
    class: "carousel-list"
};
const _hoisted_2 = {
    key: 0,
    loading: "lazy",
    class: "slide-item"
};
const _hoisted_3 = {
    key: 2,
    class: "controls"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LeftIcon = _resolveComponent("LeftIcon");
    const _component_RightIcon = _resolveComponent("RightIcon");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["carousel", { inline: _ctx.inline, 'arrows-top': _ctx.arrowsTop, 'arrows-bottom': _ctx.arrowsBottom }])
    }, [
        (_ctx.inline && Object.keys(_ctx.$slots).length > 1 && _ctx.arrowsColor != 'none' && _ctx.isMobile)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(['control-button prev', _ctx.arrowsColor, { 'disabled': _ctx.disabledPrevArrow }, { 'control-button--redesign': _ctx.enableRedesign }]),
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.prevSlide && _ctx.prevSlide(...args)))
            }, [
                _createVNode(_component_LeftIcon, {
                    class: _normalizeClass(["control-icon", { 'control-icon--redesign': _ctx.enableRedesign }])
                }, null, 8, ["class"])
            ], 2))
            : _createCommentVNode("", true),
        (_ctx.inline && Object.keys(_ctx.$slots).length > 3 && _ctx.arrowsColor != 'none' && !_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: _normalizeClass(['control-button prev', _ctx.arrowsColor, { 'disabled': _ctx.disabledPrevArrow }, { 'control-button--redesign': _ctx.enableRedesign }]),
                onClick: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.prevSlide && _ctx.prevSlide(...args)))
            }, [
                _createVNode(_component_LeftIcon, {
                    class: _normalizeClass(["control-icon", { 'control-icon--redesign': _ctx.enableRedesign }])
                }, null, 8, ["class"])
            ], 2))
            : _createCommentVNode("", true),
        _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.$slots), (slotName, index) => {
                return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: "slide"
                }, [
                    (slotName.startsWith('slide'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _renderSlot(_ctx.$slots, slotName, {}, undefined, true)
                        ]))
                        : _createCommentVNode("", true)
                ]));
            }), 128))
        ], 512),
        _withDirectives(_createElementVNode("button", {
            class: _normalizeClass(['control-button next', _ctx.arrowsColor, { 'disabled': _ctx.disabledNextArrow }, { 'control-button--redesign': _ctx.enableRedesign }]),
            onClick: _cache[2] || (_cache[2] =
                //@ts-ignore
                (...args) => (_ctx.nextSlide && _ctx.nextSlide(...args)))
        }, [
            _createVNode(_component_RightIcon, {
                class: _normalizeClass(["control-icon", { 'control-icon--redesign': _ctx.enableRedesign }])
            }, null, 8, ["class"])
        ], 2), [
            [_vShow, _ctx.inline && Object.keys(_ctx.$slots).length > 1 && _ctx.arrowsColor != 'none' && _ctx.isMobile]
        ]),
        _withDirectives(_createElementVNode("button", {
            class: _normalizeClass(['control-button next', _ctx.arrowsColor, { 'disabled': _ctx.disabledNextArrow }, { 'control-button--redesign': _ctx.enableRedesign }]),
            onClick: _cache[3] || (_cache[3] =
                //@ts-ignore
                (...args) => (_ctx.nextSlide && _ctx.nextSlide(...args)))
        }, [
            _createVNode(_component_RightIcon, {
                class: _normalizeClass(["control-icon", { 'control-icon--redesign': _ctx.enableRedesign }])
            }, null, 8, ["class"])
        ], 2), [
            [_vShow, _ctx.inline && Object.keys(_ctx.$slots).length > 3 && _ctx.arrowsColor != 'none' && !_ctx.isMobile]
        ]),
        (!_ctx.inline && Object.keys(_ctx.$slots).length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("button", {
                    class: _normalizeClass(['control-button', _ctx.arrowsColor, { 'disabled': _ctx.disabledPrevArrow }, { 'control-button--redesign': _ctx.enableRedesign }]),
                    onClick: _cache[4] || (_cache[4] =
                        //@ts-ignore
                        (...args) => (_ctx.prevSlide && _ctx.prevSlide(...args)))
                }, [
                    _createVNode(_component_LeftIcon, {
                        class: _normalizeClass(["control-icon", { 'control-icon--redesign': _ctx.enableRedesign }])
                    }, null, 8, ["class"])
                ], 2),
                _createElementVNode("button", {
                    class: _normalizeClass(['control-button', _ctx.arrowsColor, { 'control-button--redesign': _ctx.enableRedesign }]),
                    onClick: _cache[5] || (_cache[5] =
                        //@ts-ignore
                        (...args) => (_ctx.nextSlide && _ctx.nextSlide(...args)))
                }, [
                    _createVNode(_component_RightIcon, {
                        class: _normalizeClass(["control-icon", { 'control-icon--redesign': _ctx.enableRedesign }])
                    }, null, 8, ["class"])
                ], 2)
            ]))
            : _createCommentVNode("", true)
    ], 2));
}
