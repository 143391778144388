import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "vod-item" };
const _hoisted_2 = { class: "image" };
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = { class: "details" };
const _hoisted_5 = { class: "price-container" };
const _hoisted_6 = { class: "fitness_details" };
const _hoisted_7 = { class: "price f-inter" };
const _hoisted_8 = { class: "video-details f-inter" };
const _hoisted_9 = ["title", "href"];
const _hoisted_10 = { class: "duration f-inter" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ZButton = _resolveComponent("ZButton");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
                class: "image",
                src: _ctx.photo,
                alt: _ctx.title
            }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specialties, (specialty) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: specialty,
                            class: "z-b4 f-inter"
                        }, _toDisplayString(_ctx.allSpecialties[specialty]), 1));
                    }), 128))
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formattedPrice), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", null, [
                    _createElementVNode("a", {
                        class: "title",
                        title: _ctx.title,
                        href: _ctx.detailsUrl
                    }, _toDisplayString(_ctx.title), 9, _hoisted_9),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.videoDuration), 1)
                ]),
                _createVNode(_component_ZButton, {
                    variety: _ctx.buttonThemed,
                    href: _ctx.buyUrl,
                    compact: "always",
                    wide: "always",
                    class: ""
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t.buy), 1)
                    ]),
                    _: 1
                }, 8, ["variety", "href"])
            ])
        ])
    ]));
}
