import { defineComponent, reactive, watch } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import RateInstructor from '@modules/Rating/RateInstructor.vue';
import { post } from "@ts/Util/http";
import Toast from "@components/Core/Toast.vue";
import { csrfToken } from "@ts/Util/security";
const localT = {
    rateYourExperience: 'Rate your experience with {0}',
    instructorRating: 'Instructor rating',
    ratingSubmitted: 'Rating submitted',
    reviewHeader: 'Leave a Review',
    reviewBody: 'Share a review about this instructor.',
    reviewPlaceHolder: 'How was their class, their coaching style?',
    ratingSubmittedOn: 'Rating submitted {0} at {1}',
    skipForNow: 'Skip for now',
    submit: 'Submit',
    cancel: 'Cancel',
};
export default defineComponent({
    name: 'RateInstructorHandler',
    components: {
        Toast,
        RateInstructor
    },
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: (value) => validateTranslations(value, localT),
        },
        show: {
            type: Boolean,
            default: false
        },
        instructorPid: {
            type: String,
            required: true
        },
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
        instructorName: {
            type: String,
            required: true,
        },
        tippingEnabled: {
            type: Boolean,
            default: false
        },
        rating: {
            type: [Number, String],
            default: null
        },
        ratingSubmitted: {
            type: String,
            default: null
        },
        rateInstructorAction: {
            type: String,
            required: true,
        },
        rateLink: {
            type: String,
            required: true,
        },
        sendTipAction: {
            type: String,
            default: '/checkout/instructor-tip',
        },
    },
    emits: [
        'update:show',
        'update:mode',
        'update:ratingSubmitted',
        'rate-instructor:reviews-updated'
    ],
    setup(props, { emit }) {
        const state = reactive({
            mode: (Boolean(props.ratingSubmitted) ? 'edit' : 'rate'),
            disabled: false,
            toastQueue: [],
            rating: props.rating,
            ratingSubmitted: props.ratingSubmitted,
        });
        const tipOptions = [1, 3, 5, 10];
        watch(() => props.show, () => {
            state.mode = (Boolean(props.ratingSubmitted) ? 'edit' : 'rate');
        });
        return {
            state,
            tipOptions,
            storeRating(event) {
                state.disabled = true;
                saveRating(props.rateInstructorAction, event).then(response => {
                    state.rating = event.rating;
                    state.ratingSubmitted = event.date.toISOString();
                    if (props.tippingEnabled) {
                        state.mode = 'tip';
                    }
                    else {
                        emit('update:show', false);
                        state.toastQueue.push({
                            type: "success",
                            message: props.t.ratingSubmitted,
                            position: 'top-left',
                        });
                        state.mode = 'edit';
                        emit('rate-instructor:reviews-updated', response);
                    }
                }).finally(() => state.disabled = false);
            },
            handleEdit() {
                state.mode = 'edit';
            },
        };
    },
});
const saveRating = async (rateAction, data) => {
    const { protocol, hostname } = window.location;
    const requestUrl = `${protocol}//${hostname}/${rateAction}`;
    const response = await (await post(requestUrl, csrfToken(), data)).json();
    return response;
};
