import { defineComponent, computed, ref } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import CalendarIcon from '@bx-icons/regular/bx-calendar-alt.svg';
import FilterIcon from '@bx-icons/regular/bx-filter.svg';
import EditIcon from '@bx-icons/regular/bx-pencil.svg';
import { default as ClassElement } from '@components/ClassListing/ClassElement.vue';
import ClassFilterModal from '@components/ClassListing/ClassFilterModal.vue';
import DatePicker from "@components/ClassListing/DatePicker.vue";
const translationDefaults = {
    title: 'Live Classes',
    showMore: 'Show More',
    noClassesPosted: 'No classes posted.',
    add: 'add',
    noClassesAvailable: 'There are no classes available',
    viewFullSchedule: 'Click here to view full schedule'
};
export default defineComponent({
    name: 'LiveClasses',
    components: {
        CalendarIcon,
        FilterIcon,
        EditIcon,
        ClassElement,
        ClassFilterModal,
        DatePicker
    },
    props: {
        classes: {
            type: Array,
            required: true,
        },
        specialties: {
            type: Object,
            default: () => { }
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        liveClassesAction: {
            type: String,
            default: ''
        },
        hideFilters: {
            type: Boolean,
            default: false
        },
        pageSize: {
            type: Number,
            default: 5
        },
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        }
    },
    emits: [
        'no-direct-payment-modal:open'
    ],
    setup(props) {
        const visibleRecords = ref(props.pageSize);
        const showFilters = ref(false);
        const dateFilter = ref('');
        const selectedFilters = ref({
            durations: [],
            specialties: [],
            locations: [],
        });
        let durationsFilter = [], specialtiesFilter = [], locationsFilter = [];
        props.classes.forEach((classItem) => {
            let duration;
            const location = classItem.platform_type === 'In Person' ? 'inPerson' : 'virtual';
            specialtiesFilter = specialtiesFilter.concat(classItem.fitness_specialties);
            let classDuration = parseInt(classItem.duration);
            if (classDuration < 1860) {
                duration = 'underThirty';
            }
            else if (classDuration <= 3600) {
                duration = 'thirtySixty';
            }
            else {
                duration = 'overSixty';
            }
            if (!durationsFilter.includes(duration)) {
                durationsFilter.push(duration);
            }
            if (!locationsFilter.includes(location)) {
                locationsFilter.push(location);
            }
        });
        const availableFilters = ref({
            durations: durationsFilter,
            specialties: [...new Set(specialtiesFilter)],
            locations: locationsFilter
        });
        const filteredClasses = computed(() => {
            let { durations, specialties, locations } = selectedFilters.value;
            if (!props.classes) {
                return [];
            }
            return props.classes.filter((classItem) => {
                if (dateFilter.value) {
                    const dateFormatOpts = { year: 'numeric', month: 'numeric', day: 'numeric' };
                    const classDate = new Intl.DateTimeFormat('en-US', dateFormatOpts).format(new Date(classItem.effective_date_utc));
                    const filterDate = new Intl.DateTimeFormat('en-US', dateFormatOpts).format(new Date(dateFilter.value + 'T00:00:00'));
                    if (filterDate !== classDate)
                        return false;
                }
                if (durations.length &&
                    durations.every((d) => d.min > classItem.duration || d.max < classItem.duration)) {
                    return false;
                }
                if (specialties.length &&
                    classItem.fitness_specialties.every(s => !specialties.includes(s))) {
                    return false;
                }
                if (locations.length) {
                    const isInPerson = classItem.platform_type === 'In Person';
                    const isVirtual = classItem.platform_type === 'Zoom';
                    if (isVirtual && !locations.includes('virtual')) {
                        return false;
                    }
                    if (isInPerson && !locations.includes('inPerson')) {
                        return false;
                    }
                }
                return true;
            });
        });
        const filterCount = computed(() => {
            let { durations, specialties, locations } = selectedFilters.value;
            return durations.length + specialties.length + locations.length;
        });
        const visibleClasses = computed(() => {
            return filteredClasses.value.slice(0, visibleRecords.value);
        });
        const clearFilters = () => {
            dateFilter.value = '';
            selectedFilters.value = {
                durations: [],
                specialties: [],
                locations: [],
            };
        };
        return {
            visibleRecords,
            showFilters,
            dateFilter,
            selectedFilters,
            availableFilters,
            filteredClasses,
            visibleClasses,
            filterCount,
            clearFilters
        };
    }
});
