import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "date-picker" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = ["value"];
import { ref } from 'vue';
import CalendarIcon from '@bx-icons/regular/bx-calendar-alt.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DatePicker',
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const selectedDate = ref('');
        const onInput = (e) => {
            selectedDate.value = e.target.value;
            setTimeout(() => {
                emit('update:modelValue', selectedDate.value);
            }, 1000);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (!!_ctx.$slots?.icon)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                        _renderSlot(_ctx.$slots, "icon")
                    ]))
                    : (_openBlock(), _createBlock(_unref(CalendarIcon), {
                        key: 1,
                        class: "date-picker__icon"
                    })),
                _createElementVNode("input", _mergeProps({ value: selectedDate.value }, _ctx.$attrs, {
                    type: "date",
                    onInput: onInput
                }), null, 16, _hoisted_3)
            ]));
        };
    }
});
