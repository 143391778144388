import { defineComponent, ref } from 'vue';
import { isMobileReactive } from '@ts/Util/responsiveness';
import LeftIcon from '@bx-icons/regular/bx-left-arrow-alt.svg';
import RightIcon from '@bx-icons/regular/bx-right-arrow-alt.svg';
const behavior = 'smooth';
export default defineComponent({
    name: 'Carousel',
    components: {
        LeftIcon,
        RightIcon,
    },
    props: {
        inline: {
            type: Boolean,
            default: false
        },
        arrowsColor: {
            type: String,
            default: 'dark',
        },
        arrowsTop: {
            type: Boolean,
            default: false,
        },
        arrowsBottom: {
            type: Boolean,
            default: false,
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
    },
    setup() {
        const carousel = ref(null);
        const isMobile = isMobileReactive();
        const disabledPrevArrow = ref(true);
        const disabledNextArrow = ref(false);
        const maxLeft = () => {
            const slide = carousel.value?.firstElementChild;
            return slide.offsetWidth * carousel.value.childElementCount;
        };
        const prevSlide = () => {
            const slide = carousel.value?.firstElementChild;
            let left = carousel.value.scrollLeft - slide.offsetWidth;
            disabledNextArrow.value = false;
            if (left < 0) {
                disabledPrevArrow.value = true;
            }
            carousel.value?.scrollTo({ left, behavior });
        };
        const nextSlide = () => {
            const slide = carousel.value?.firstElementChild;
            let left = carousel.value.scrollLeft + slide.offsetWidth;
            disabledPrevArrow.value = false;
            if (left + carousel.value.offsetWidth - slide.offsetWidth >= maxLeft()) {
                disabledNextArrow.value = true;
            }
            carousel.value?.scrollTo({ left, behavior });
        };
        return {
            carousel,
            isMobile,
            disabledPrevArrow,
            disabledNextArrow,
            prevSlide,
            nextSlide
        };
    },
});
