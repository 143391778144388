import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["href"];
const _hoisted_2 = ["href"];
const _hoisted_3 = ["href"];
const _hoisted_4 = ["href"];
const _hoisted_5 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_FacebookIcon = _resolveComponent("FacebookIcon");
    const _component_LinkedinIcon = _resolveComponent("LinkedinIcon");
    const _component_InstagramIcon = _resolveComponent("InstagramIcon");
    const _component_TiktokIcon = _resolveComponent("TiktokIcon");
    const _component_SpotifyIcon = _resolveComponent("SpotifyIcon");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["social-links", _ctx.classes])
    }, [
        (_ctx.parsedSocialLinks.facebook)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "social-link",
                href: _ctx.parsedSocialLinks.facebook,
                target: "_blank",
                rel: "noopener"
            }, [
                _createVNode(_component_FacebookIcon, { class: "social-icon facebook-icon" })
            ], 8, _hoisted_1))
            : _createCommentVNode("", true),
        (_ctx.parsedSocialLinks.linkedin)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "social-link",
                href: _ctx.parsedSocialLinks.linkedin,
                target: "_blank",
                rel: "noopener"
            }, [
                _createVNode(_component_LinkedinIcon, { class: "social-icon linkedin-icon" })
            ], 8, _hoisted_2))
            : _createCommentVNode("", true),
        (_ctx.parsedSocialLinks.instagram)
            ? (_openBlock(), _createElementBlock("a", {
                key: 2,
                class: "social-link",
                href: _ctx.parsedSocialLinks.instagram,
                target: "_blank",
                rel: "noopener"
            }, [
                _createVNode(_component_InstagramIcon, { class: "social-icon instagram-icon" })
            ], 8, _hoisted_3))
            : _createCommentVNode("", true),
        (_ctx.parsedSocialLinks.tiktok)
            ? (_openBlock(), _createElementBlock("a", {
                key: 3,
                class: "social-link",
                href: _ctx.parsedSocialLinks.tiktok,
                target: "_blank",
                rel: "noopener"
            }, [
                _createVNode(_component_TiktokIcon, { class: "social-icon tiktok-icon" })
            ], 8, _hoisted_4))
            : _createCommentVNode("", true),
        (_ctx.parsedSocialLinks.spotify)
            ? (_openBlock(), _createElementBlock("a", {
                key: 4,
                class: "social-link",
                href: _ctx.parsedSocialLinks.spotify,
                target: "_blank",
                rel: "noopener"
            }, [
                _createVNode(_component_SpotifyIcon, { class: "social-icon spotify-icon" })
            ], 8, _hoisted_5))
            : _createCommentVNode("", true)
    ], 2));
}
