import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "filters__header" };
const _hoisted_2 = { class: "f-inter" };
const _hoisted_3 = {
    key: 0,
    class: "filters__duration f-inter"
};
const _hoisted_4 = { class: "filters-section__title f-inter" };
const _hoisted_5 = { class: "filters__check-group" };
const _hoisted_6 = { class: "f-inter" };
const _hoisted_7 = { class: "f-inter" };
const _hoisted_8 = { class: "f-inter" };
const _hoisted_9 = {
    key: 1,
    class: "filters__fitness-type f-inter"
};
const _hoisted_10 = { class: "filters-section__title f-inter" };
const _hoisted_11 = { class: "filters__check-group" };
const _hoisted_12 = { class: "f-inter" };
const _hoisted_13 = {
    key: 2,
    class: "filters__location f-inter"
};
const _hoisted_14 = { class: "filters-section__title f-inter" };
const _hoisted_15 = { class: "filters__check-group" };
const _hoisted_16 = { class: "f-inter" };
const _hoisted_17 = { class: "f-inter" };
const _hoisted_18 = { class: "filters__buttons" };
import { isMobileReactive } from '@ts/Util/responsiveness';
import Modal from '@components/Core/Modal.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import FormCheckbox from '@components/Core/FormCheckbox.vue';
import ZButton from '@components/Core/ZButton.vue';
import { computed, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ClassFilterModal',
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        specialties: {
            type: Object,
            default: () => { }
        },
        availableFilters: {
            type: Object,
            default: () => {
                return { durations: [], locations: [], specialties: [] };
            }
        },
        mobileDrawer: {
            type: Boolean,
            default: () => false
        },
        t: {
            type: Object,
            default: () => ({
                clearAll: 'Clear all',
                cancel: 'Cancel',
                filters: 'Filters',
                applyFilter: 'Apply Filter',
                duration: 'Duration',
                underThirtyMin: 'Under 30 min',
                thirtyToSixtyMin: '30 - 60 min',
                overSixtyMin: 'Over 60 min',
                fitnessType: 'Fitness Type',
                location: 'Location',
                inPerson: 'In Person',
                virtual: 'Virtual',
            })
        }
    },
    emits: ['closed', 'apply'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const customModalStyles = computed(() => {
            const isMobile = isMobileReactive();
            const modalStyle = {
                border: 0,
                width: isMobile.value ? '100%' : '38rem',
                paddingTop: isMobile.value ? '1.25rem' : '2.5rem',
                paddingBottom: isMobile.value ? '1.25rem' : '2.5rem',
                paddingLeft: isMobile.value ? '1.25rem' : '2.75rem',
                paddingRight: isMobile.value ? '1.25rem' : '2.75rem'
            };
            if (isMobile.value) {
                modalStyle.bottom = '1.25rem';
                modalStyle.left = 0;
                modalStyle.top = 'initial';
                modalStyle.transform = 'none';
                modalStyle.borderRadius = '0.5rem 0.5rem 0 0';
            }
            return {
                modal: modalStyle
            };
        });
        const selectedDurations = ref({
            underThirty: false,
            thirtySixty: false,
            overSixty: false
        });
        const selectedSpecialties = ref({});
        const selectedLocations = ref({
            inPerson: false,
            virtual: false,
        });
        const durationMinMax = computed(() => {
            const minMax = [];
            if (selectedDurations.value.underThirty) {
                minMax.push({ min: 0, max: 1800 });
            }
            if (selectedDurations.value.thirtySixty) {
                minMax.push({ min: 1801, max: 3600 });
            }
            if (selectedDurations.value.overSixty) {
                minMax.push({ min: 3601, max: 999999 });
            }
            return minMax;
        });
        const applyFilters = () => {
            emit('apply', {
                durations: durationMinMax.value,
                specialties: Object.keys(selectedSpecialties.value)
                    .filter(k => !!selectedSpecialties.value[k]),
                locations: Object.keys(selectedLocations.value)
                    .filter(k => !!selectedLocations.value[k]),
            });
            emit('closed');
        };
        const clearFilters = () => {
            selectedSpecialties.value = {};
            Object.keys(selectedDurations.value).forEach(k => selectedDurations.value[k] = false);
            Object.keys(selectedLocations.value).forEach(k => selectedLocations.value[k] = false);
        };
        const clearAndApplyFilters = (() => {
            clearFilters();
            applyFilters();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(Modal, {
                show: __props.show,
                "close-when-click-on-background": "",
                "close-when-type-esc": "",
                "enable-scroll": "",
                "mobile-drawer": __props.mobileDrawer,
                "show-close-button": false,
                "custom-styles": customModalStyles.value,
                onClosed: _cache[6] || (_cache[6] = ($event) => (emit('closed')))
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("h4", _hoisted_2, _toDisplayString(__props.t.filters), 1),
                        _createElementVNode("a", {
                            class: "filters__clear f-inter",
                            href: "#",
                            onClick: _withModifiers(clearAndApplyFilters, ["prevent"])
                        }, _toDisplayString(__props.t.clearAll), 1)
                    ]),
                    _createVNode(HorizontalSeparator),
                    (__props.availableFilters.durations.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("h5", _hoisted_4, _toDisplayString(__props.t.duration), 1),
                            _createElementVNode("div", _hoisted_5, [
                                (__props.availableFilters.durations.includes('underThirty'))
                                    ? (_openBlock(), _createBlock(FormCheckbox, {
                                        key: 0,
                                        modelValue: selectedDurations.value.underThirty,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((selectedDurations.value.underThirty) = $event)),
                                        class: "filters__check-group-option",
                                        name: "duration_under_thirty"
                                    }, {
                                        label: _withCtx(() => [
                                            _createElementVNode("p", _hoisted_6, _toDisplayString(__props.t.underThirtyMin), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["modelValue"]))
                                    : _createCommentVNode("", true),
                                (__props.availableFilters.durations.includes('thirtySixty'))
                                    ? (_openBlock(), _createBlock(FormCheckbox, {
                                        key: 1,
                                        modelValue: selectedDurations.value.thirtySixty,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((selectedDurations.value.thirtySixty) = $event)),
                                        class: "filters__check-group-option",
                                        name: "duration_thirty_sixty"
                                    }, {
                                        label: _withCtx(() => [
                                            _createElementVNode("p", _hoisted_7, _toDisplayString(__props.t.thirtyToSixtyMin), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["modelValue"]))
                                    : _createCommentVNode("", true),
                                (__props.availableFilters.durations.includes('overSixty'))
                                    ? (_openBlock(), _createBlock(FormCheckbox, {
                                        key: 2,
                                        modelValue: selectedDurations.value.overSixty,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((selectedDurations.value.overSixty) = $event)),
                                        class: "filters__check-group-option",
                                        name: "duration_over_sixty"
                                    }, {
                                        label: _withCtx(() => [
                                            _createElementVNode("p", _hoisted_8, _toDisplayString(__props.t.overSixtyMin), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["modelValue"]))
                                    : _createCommentVNode("", true)
                            ]),
                            _createVNode(HorizontalSeparator)
                        ]))
                        : _createCommentVNode("", true),
                    (__props.availableFilters.specialties?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("h5", _hoisted_10, _toDisplayString(__props.t.fitnessType), 1),
                            _createElementVNode("div", _hoisted_11, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.specialties, (text, slug) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                                        (__props.availableFilters.specialties?.includes(slug))
                                            ? (_openBlock(), _createElementBlock("div", { key: slug }, [
                                                _createVNode(FormCheckbox, {
                                                    modelValue: selectedSpecialties.value[slug],
                                                    "onUpdate:modelValue": ($event) => ((selectedSpecialties.value[slug]) = $event),
                                                    class: "filters__check-group-option",
                                                    name: `specialties_${slug.replace(/-./g, '_')}`
                                                }, {
                                                    label: _withCtx(() => [
                                                        _createElementVNode("p", _hoisted_12, _toDisplayString(text), 1)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["modelValue", "onUpdate:modelValue", "name"])
                                            ]))
                                            : _createCommentVNode("", true)
                                    ], 64));
                                }), 256))
                            ]),
                            _createVNode(HorizontalSeparator)
                        ]))
                        : _createCommentVNode("", true),
                    (__props.availableFilters.locations?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("h5", _hoisted_14, _toDisplayString(__props.t.location), 1),
                            _createElementVNode("div", _hoisted_15, [
                                _createVNode(FormCheckbox, {
                                    modelValue: selectedLocations.value.inPerson,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((selectedLocations.value.inPerson) = $event)),
                                    class: "filters__check-group-option",
                                    name: "location_in_person f-inter"
                                }, {
                                    label: _withCtx(() => [
                                        _createElementVNode("p", _hoisted_16, _toDisplayString(__props.t.inPerson), 1)
                                    ]),
                                    _: 1
                                }, 8, ["modelValue"]),
                                _createVNode(FormCheckbox, {
                                    modelValue: selectedLocations.value.virtual,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((selectedLocations.value.virtual) = $event)),
                                    class: "filters__check-group-option",
                                    name: "location_virtual f-inter"
                                }, {
                                    label: _withCtx(() => [
                                        _createElementVNode("p", _hoisted_17, _toDisplayString(__props.t.virtual), 1)
                                    ]),
                                    _: 1
                                }, 8, ["modelValue"])
                            ]),
                            _createVNode(HorizontalSeparator)
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_18, [
                        _createVNode(ZButton, {
                            class: "filters__buttons-button f-inter",
                            wide: "mobile-only",
                            variety: "plum",
                            onClick: _cache[5] || (_cache[5] = ($event) => (emit('closed')))
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(__props.t.cancel), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(ZButton, {
                            class: "filters__buttons-button f-inter",
                            wide: "mobile-only",
                            variety: "plum",
                            mode: "dark",
                            onClick: applyFilters
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(__props.t.applyFilter), 1)
                            ]),
                            _: 1
                        })
                    ])
                ]),
                _: 1
            }, 8, ["show", "mobile-drawer", "custom-styles"]));
        };
    }
});
