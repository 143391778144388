import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'CopyableText',
    props: {
        animateDuration: {
            type: Number,
            default: 3500,
        },
        allowFlash: {
            type: Boolean,
            default: true
        }
    },
    emits: ['copied'],
    setup(props, { emit }) {
        const displayCopied = ref(false);
        const copyTextToClipboard = (text) => {
            navigator.clipboard.writeText(text);
            emit('copied');
            displayCopied.value = true;
            setTimeout(() => displayCopied.value = false, props.animateDuration);
        };
        return {
            displayCopied,
            copyTextToClipboard,
        };
    }
});
