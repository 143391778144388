import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "review f-inter" };
const _hoisted_2 = { class: "review__student-avatar" };
const _hoisted_3 = ["src"];
const _hoisted_4 = {
    key: 1,
    class: "default-student-avatar"
};
const _hoisted_5 = { class: "student-initials" };
const _hoisted_6 = { class: "review__content" };
const _hoisted_7 = { class: "review__student-name z-b2" };
const _hoisted_8 = { class: "review__date z-b4" };
const _hoisted_9 = {
    key: 0,
    class: "review__class-name z-b3"
};
const _hoisted_10 = {
    key: 1,
    class: "review__details z-b2"
};
import { computed, ref } from 'vue';
import Rating from './Rating';
import { currentUserLocale } from "@ts/Util/i18n";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Review',
    props: {
        review: {
            type: Object,
            required: true
        },
        t: {
            type: Object,
            default: () => ({
                at: 'at',
            })
        }
    },
    setup(__props) {
        const props = __props;
        const reviewDateText = computed(() => {
            const dateFormatOpts = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
            return new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(new Date(props.review.created));
        });
        const reviewTimeText = computed(() => {
            const timeFormatOpts = { hour: 'numeric', minute: '2-digit' };
            return new Intl.DateTimeFormat(currentUserLocale(), timeFormatOpts).format(new Date(props.review.created));
        });
        const firstName = ref(props.review.first_name);
        const lastName = ref(props.review.last_name);
        const studentInitials = computed(() => {
            const initials = `${firstName.value.charAt(0)}${lastName.value.charAt(0)}`;
            return initials.toUpperCase();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    (__props.review.profile_photo_url)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: __props.review.profile_photo_url + '?w=40&h=40&fit=crop&auto=format,compress 2x'
                        }, null, 8, _hoisted_3))
                        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("span", _hoisted_5, _toDisplayString(studentInitials.value), 1)
                        ]))
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(__props.review.display_name), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(reviewDateText.value) + " " + _toDisplayString(__props.t.at) + " " + _toDisplayString(reviewTimeText.value), 1),
                    _createVNode(_unref(Rating), {
                        score: __props.review.rating
                    }, null, 8, ["score"]),
                    (__props.review.class_title)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(__props.review.class_title), 1))
                        : _createCommentVNode("", true),
                    (__props.review.comment)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(__props.review.comment), 1))
                        : _createCommentVNode("", true)
                ])
            ]));
        };
    }
});
