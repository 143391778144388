import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "class-element__info" };
const _hoisted_2 = { class: "class-element__date z-b2" };
const _hoisted_3 = { class: "class-element__time" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { class: "class-element__details" };
const _hoisted_6 = {
    key: 0,
    class: "class-element__details-specialties"
};
const _hoisted_7 = { class: "z-b4" };
const _hoisted_8 = ["href"];
const _hoisted_9 = ["innerHTML"];
const _hoisted_10 = {
    key: 1,
    class: "z-b4"
};
const _hoisted_11 = { class: "class-element__cta" };
const _hoisted_12 = {
    key: 0,
    class: "class-element__price"
};
const _hoisted_13 = {
    key: 1,
    class: "class-element__price"
};
const _hoisted_14 = { class: "class-element__cta-elements" };
const _hoisted_15 = {
    key: 0,
    class: "class-element__cta-extras f-inter z-b5"
};
const _hoisted_16 = {
    key: 1,
    class: "class-element__cta-extras f-inter z-b5"
};
import { computed } from 'vue';
import { formatCurrencyStr } from "@ts/Util/currency";
import { currentUserLocale } from '@ts/Util/i18n';
import ZButton from "@components/Core/ZButton.vue";
import { theme, ThemeType } from "@ts/Util/theme";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ClassElement',
    props: {
        specialties: {
            type: Object,
            default: () => { }
        },
        classItem: {
            type: Object,
            required: true
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            default: () => ({
                min: 'min',
                register: 'Register',
                soldOut: 'Sold Out',
                details: 'Details',
                locationRequiresMembership: 'Location requires Membership.',
                freePassAvailable: 'Free pass available.',
                zoom: 'Zoom',
                lowIntensity: 'Low Intensity',
                mediumIntensity: 'Medium Intensity',
                highIntensity: 'High Intensity'
            })
        },
    },
    emits: ['no-direct-payment-modal:open'],
    setup(__props, { emit: __emit }) {
        const PLATFORM_TYPES = {
            VOD: 'VOD',
            ZOOM: 'Zoom',
            IN_PERSON: 'In Person',
        };
        const props = __props;
        const priceFormatted = computed(() => {
            return formatCurrencyStr(props.classItem.price || 0, props.classItem.price_currency, currentUserLocale(), true);
        });
        const classSpecialties = computed(() => {
            const classSpecialties = [];
            props.classItem.fitness_specialties.forEach((speciality) => {
                if (props.specialties[speciality])
                    classSpecialties.push(props.specialties[speciality]);
            });
            return classSpecialties;
        });
        const classDuration = computed(() => {
            return Math.floor(parseInt(props.classItem.duration) / 60);
        });
        const classDateText = computed(() => {
            const dateFormatOpts = { weekday: 'long', month: 'short', day: 'numeric' };
            const classDate = (props.classItem.platform_type === PLATFORM_TYPES.IN_PERSON && props.classItem.effective_date) ?
                new Date(props.classItem.effective_date) : new Date(props.classItem.effective_date_utc);
            let classDateTimeFormatted = "";
            try {
                classDateTimeFormatted = new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(classDate);
            }
            catch (error) {
                //safari 15 support: https://github.com/w3c/respec/issues/1357
                const classDate = (props.classItem.platform_type === PLATFORM_TYPES.IN_PERSON && props.classItem.effective_date) ?
                    new Date(props.classItem.effective_date.replace(/-/g, "/")) : new Date(props.classItem.effective_date_utc);
                classDateTimeFormatted = new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(classDate);
            }
            return classDateTimeFormatted;
        });
        const classTimeText = computed(() => {
            const timeFormatOpts = { hour: 'numeric', minute: '2-digit' };
            const classDate = (props.classItem.platform_type === PLATFORM_TYPES.IN_PERSON && props.classItem.effective_date) ?
                new Date(props.classItem.effective_date) : new Date(props.classItem.effective_date_utc);
            let classDateTimeFormatted = "";
            try {
                classDateTimeFormatted = new Intl.DateTimeFormat(currentUserLocale(), timeFormatOpts).format(classDate);
            }
            catch (e) {
                //safari 15 support: https://github.com/w3c/respec/issues/1357
                const classDate = (props.classItem.platform_type === PLATFORM_TYPES.IN_PERSON && props.classItem.effective_date) ?
                    new Date(props.classItem.effective_date.replace(/-/g, "/")) : new Date(props.classItem.effective_date_utc);
                classDateTimeFormatted = new Intl.DateTimeFormat(currentUserLocale(), timeFormatOpts).format(classDate);
            }
            return classDateTimeFormatted;
        });
        const emits = __emit;
        const ctaText = computed(() => {
            if (!props.classItem?.location_requires_membership || props.classItem.platform_type !== PLATFORM_TYPES.IN_PERSON) {
                return (!props.classItem.sold_out) ? props.t.register : props.t.soldOut;
            }
            return props.t.details;
        });
        const handleBookClass = () => {
            if (!!props.classItem?.can_accept_payments || props.classItem.platform_type !== PLATFORM_TYPES.IN_PERSON) {
                window.location.href = `${props.classItem.buyUrl}?potential_source=${encodeURIComponent('Instructor Page')}`;
            }
            else {
                emits('no-direct-payment-modal:open', props.classItem.id);
            }
        };
        const formattedAddress = computed(() => {
            const address = props.classItem?.address ?? '';
            const formattedAddressesParts = [...new Set(address.split(', '))];
            return formattedAddressesParts.join(', ');
        });
        const addressText = computed(() => {
            return formattedAddress.value ?? props.classItem.platform_type;
        });
        const buttonThemed = theme.value === ThemeType.Zumba ? 'rogue' : 'love';
        const intensityString = computed(() => {
            switch (props.classItem.workout_level) {
                case 'low':
                    return props.t.lowIntensity;
                case 'medium':
                    return props.t.mediumIntensity;
                case 'high':
                    return props.t.highIntensity;
                default:
                    return '';
            }
        });
        const specialtiesAndLevelString = computed(() => {
            const fitnessDescription = [];
            if (classSpecialties.value.length > 0) {
                fitnessDescription.push(classSpecialties.value.join(' / '));
            }
            if (intensityString.value !== '') {
                fitnessDescription.push(intensityString.value);
            }
            return fitnessDescription.join(' • ');
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["class-element f-inter", { 'class-element--redesign': __props.enableRedesign }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", null, _toDisplayString(classDateText.value), 1)
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        _createTextVNode(_toDisplayString(classTimeText.value) + " ", 1),
                        (__props.classItem.duration)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(classDuration.value) + " " + _toDisplayString(__props.t.min) + ")", 1))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                    (classSpecialties.value.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("span", _hoisted_7, _toDisplayString(specialtiesAndLevelString.value), 1)
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("a", {
                        class: "class-element__title p f-inter",
                        href: __props.classItem.detailsUrl
                    }, _toDisplayString(__props.classItem.title), 9, _hoisted_8),
                    _createElementVNode("div", {
                        class: "class-element__address z-b4",
                        innerHTML: addressText.value
                    }, null, 8, _hoisted_9),
                    (__props.classItem.platform_type === PLATFORM_TYPES.ZOOM)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(__props.t.zoom), 1))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_11, [
                    (__props.classItem.platform_type !== PLATFORM_TYPES.IN_PERSON)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(priceFormatted.value), 1))
                        : (__props.classItem.can_accept_payments && __props.classItem.price)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(priceFormatted.value), 1))
                            : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_14, [
                        _createVNode(ZButton, {
                            class: "class-element__book-button",
                            disabled: __props.classItem.sold_out,
                            variety: _unref(buttonThemed),
                            onClick: handleBookClass
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(ctaText.value), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled", "variety"]),
                        (!__props.classItem.can_accept_payments && __props.classItem.location_requires_membership)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(__props.t.locationRequiresMembership), 1))
                            : _createCommentVNode("", true),
                        (!__props.classItem.can_accept_payments && __props.classItem.free_passes_available)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(__props.t.freePassAvailable), 1))
                            : _createCommentVNode("", true)
                    ])
                ])
            ], 2));
        };
    }
});
