import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "instructor-section__title--redesign z-h1"
};
const _hoisted_2 = {
    key: 1,
    class: "instructor-section__title f-inter"
};
const _hoisted_3 = ["href"];
const _hoisted_4 = {
    key: 3,
    class: "break--redesign"
};
const _hoisted_5 = { class: "instructor-section__filters" };
const _hoisted_6 = {
    key: 0,
    class: "vods-list__filter-count z-b5"
};
const _hoisted_7 = {
    key: 0,
    class: "vods-list"
};
const _hoisted_8 = { key: 1 };
const _hoisted_9 = { class: "vods-list--empty-container f-inter" };
const _hoisted_10 = {
    key: 2,
    class: "vods-list--empty-container f-inter"
};
const _hoisted_11 = { class: "z-b3" };
const _hoisted_12 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PencilIcon = _resolveComponent("PencilIcon");
    const _component_FilterSvg = _resolveComponent("FilterSvg");
    const _component_VODItem = _resolveComponent("VODItem");
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_ClassFilterModal = _resolveComponent("ClassFilterModal");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass({ 'instructor-section__bg--redesign': _ctx.enableRedesign && !_ctx.memberView,
            'instructor-section__bg--redesign-edit': _ctx.enableRedesign && _ctx.memberView })
    }, [
        (_ctx.editMode || _ctx.videos.length)
            ? (_openBlock(), _createElementBlock("section", {
                key: 0,
                class: _normalizeClass(["instructor-section", { 'instructor-section--redesign': _ctx.enableRedesign }])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["instructor-section__header", { 'instructor-section__header--redesign': _ctx.enableRedesign && !_ctx.editMode,
                            'instructor-section__header--redesign-edit': _ctx.enableRedesign && _ctx.editMode }])
                }, [
                    (_ctx.enableRedesign)
                        ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.t.videoOnDemand), 1))
                        : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.t.videoOnDemand), 1)),
                    (_ctx.editMode)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            class: "vods-edit-link",
                            href: _ctx.editLink
                        }, [
                            _createVNode(_component_PencilIcon)
                        ], 8, _hoisted_3))
                        : _createCommentVNode("", true),
                    (_ctx.enableRedesign && _ctx.editMode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_5, [
                        (_ctx.videos.length && !_ctx.hideFilters)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: _normalizeClass(["vods-filter", { 'vods-filter--redesign': _ctx.enableRedesign }]),
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.showFilters = true), ["prevent"]))
                            }, [
                                (_ctx.filterCount > 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.filterCount), 1))
                                    : _createCommentVNode("", true),
                                _createVNode(_component_FilterSvg)
                            ], 2))
                            : _createCommentVNode("", true)
                    ])
                ], 2),
                (_ctx.visibleVideos.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleVideos, (video) => {
                            return (_openBlock(), _createBlock(_component_VODItem, {
                                id: video.id,
                                key: video.id,
                                title: video.title,
                                photo: video.video_photo,
                                duration: video.video_duration,
                                price: video.price,
                                currency: video.price_currency,
                                specialties: video.fitness_specialties,
                                "all-specialties": _ctx.specialties,
                                "vods-action": video.url,
                                "details-url": video.detailsUrl,
                                "buy-url": video.buyUrl,
                                "enable-redesign": _ctx.enableRedesign,
                                t: _ctx.t
                            }, null, 8, ["id", "title", "photo", "duration", "price", "currency", "specialties", "all-specialties", "vods-action", "details-url", "buy-url", "enable-redesign", "t"]));
                        }), 128))
                    ]))
                    : (_ctx.videos?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                                _createTextVNode(_toDisplayString(_ctx.t.noVODSAvailable) + " ", 1),
                                _createElementVNode("a", {
                                    class: "z-b3 f-inter",
                                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (_ctx.clearFilters()), ["prevent"]))
                                }, _toDisplayString(_ctx.t.viewFullSchedule), 1)
                            ])
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t.noVideosPosted), 1),
                            _createElementVNode("a", {
                                class: "z-b3",
                                href: _ctx.addLink
                            }, _toDisplayString(_ctx.t.add), 9, _hoisted_12)
                        ])),
                (_ctx.filteredClasses.length > _ctx.visibleRecords)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: _normalizeClass({ 'vods-list--show-more--redesign': _ctx.enableRedesign, 'vods-list--show-more': !_ctx.enableRedesign })
                    }, [
                        (_ctx.enableRedesign)
                            ? (_openBlock(), _createBlock(_component_ZButton, {
                                key: 0,
                                variety: "optimus",
                                wide: "never",
                                onClick: _cache[2] || (_cache[2] = _withModifiers(($event) => (_ctx.visibleRecords += 3), ["prevent"]))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t.showMore), 1)
                                ]),
                                _: 1
                            }))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                class: "f-inter",
                                onClick: _cache[3] || (_cache[3] = _withModifiers(($event) => (_ctx.visibleRecords += 3), ["prevent"]))
                            }, _toDisplayString(_ctx.t.showMore), 1))
                    ], 2))
                    : _createCommentVNode("", true),
                _createVNode(_component_ClassFilterModal, {
                    t: _ctx.t,
                    show: _ctx.showFilters,
                    specialties: _ctx.specialties,
                    "available-filters": _ctx.availableFilters,
                    onApply: _cache[4] || (_cache[4] = (filters) => _ctx.selectedFilters = filters),
                    onClosed: _cache[5] || (_cache[5] = ($event) => (_ctx.showFilters = false))
                }, null, 8, ["t", "show", "specialties", "available-filters"])
            ], 2))
            : _createCommentVNode("", true)
    ], 2));
}
