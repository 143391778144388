import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "redesign-margin-top"
};
const _hoisted_2 = {
    id: "instructor-reviews",
    class: "instructor-section"
};
const _hoisted_3 = { class: "instructor-section__header" };
const _hoisted_4 = { class: "instructor-section--title f-inter" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "review-cta f-inter z-b3" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
    key: 0,
    class: "review-cta__self-rate-error"
};
const _hoisted_10 = { class: "review-list" };
const _hoisted_11 = {
    key: 1,
    class: "review-list__show-more"
};
const _hoisted_12 = { class: "review-list-desktop" };
const _hoisted_13 = { class: "review-list-column" };
const _hoisted_14 = { class: "review-list-column" };
const _hoisted_15 = {
    key: 2,
    class: "review-list__show-more-desktop"
};
const _hoisted_16 = { key: 1 };
const _hoisted_17 = { class: "no-reviews z-b3 f-inter" };
const _hoisted_18 = ["onClick"];
const _hoisted_19 = { key: 2 };
const _hoisted_20 = { class: "no-reviews z-b3 f-inter other-instructor" };
const _hoisted_21 = {
    key: 0,
    class: "reviews-count"
};
const _hoisted_22 = {
    key: 1,
    class: "review-cta__self-rate-error self-rate-spacing"
};
import { computed, reactive } from 'vue';
import StarFilledSvg from '@bx-icons/solid/bxs-star.svg';
import { default as Review } from '@components/Reviews/Review.vue';
import RateInstructorHandler from "@modules/Rating/RateInstructorHandler.vue";
import CopyableText from "@components/Core/CopyableText.vue";
import { currentUserLocale } from "@ts/Util/i18n";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Reviews',
    props: {
        ratingScore: {
            type: Number,
            required: true,
            validator: (value) => value >= 0 && value <= 5,
        },
        showRatingModalInitially: {
            type: Boolean,
            required: true,
        },
        editMode: {
            type: Boolean,
            required: true,
        },
        isOwnProfile: {
            type: Boolean,
            required: true,
        },
        instructorName: {
            type: String,
            required: true,
        },
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
        numReviews: {
            type: Number,
            default: 0,
            validator: (value) => value >= 0,
        },
        reviews: {
            type: Array,
            required: true,
        },
        rateLink: {
            type: String,
            required: true
        },
        instructorRatingSubmitted: {
            type: String,
            required: true
        },
        studentInstructorRating: {
            type: [Number, String],
            required: true,
        },
        instructorPid: {
            type: String,
            required: true,
        },
        rateInstructorAction: {
            type: String,
            required: true,
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            default: () => ({
                title: 'Ratings',
                ratings: 'Ratings',
                reviews: 'reviews',
                rateNow: 'Rate Now',
                showMore: 'Show More',
                at: 'at',
                rateYourExperience: 'Rate your experience with {0}',
                instructorRating: 'Instructor rating',
                ratingSubmitted: 'Rating submitted',
                reviewHeader: 'Leave a review',
                reviewBody: 'Share a review about this instructor.',
                reviewPlaceHolder: 'How was their class, their coaching style?',
                ratingSubmittedOn: 'Rating submitted on {0} at {1}',
                skipForNow: 'Skip for now',
                submit: 'Submit',
                cancel: 'Cancel',
                copyRatingLink: 'Copy rating link',
                copied: 'Copied!',
                askForSomeLove: 'Ask for some love from your fans.',
                youCannotRateYourself: 'You cannot rate yourself.',
                beFirstOneShowLove: 'This instructor doesn’t have any review to show for now. Be the first to show some love!',
            })
        }
    },
    setup(__props) {
        const props = __props;
        const state = reactive({
            ratingScore: props.ratingScore,
            numReviews: props.numReviews,
            reviews: (props.reviews || []).slice(0),
            visibleRecords: 6,
            visibleReviews: computed(() => {
                return state.reviews.slice(0, state.visibleRecords);
            }),
            numReviewsFormatted: computed(() => {
                const reviews = (props.reviews || []).filter((rate) => {
                    return !!rate.comment;
                });
                return reviews.length;
            }),
            numRatesFormatted: computed(() => {
                return state.numReviews.toString();
            }),
            ratingScoreFormatted: computed(() => {
                const score = Math.round(state.ratingScore * 10) / 10;
                return Intl.NumberFormat(currentUserLocale(), {
                    minimumSignificantDigits: 2,
                    maximumSignificantDigits: 2,
                }).format(score);
            }),
            showRating: Boolean(props.showRatingModalInitially &&
                !props.isOwnProfile &&
                !props.editMode &&
                props.isLoggedIn),
            showSelfRatingError: false,
            visibleReviewsSplit: computed(() => splitReviews())
        });
        const updateRatingsAndReviews = (ratingData) => {
            state.reviews = ratingData.reviews;
            state.numReviews = ratingData.num_reviews;
            state.ratingScore = ratingData.rating_score;
        };
        function tryToShowRatingModal() {
            if (props.isOwnProfile || props.editMode) {
                state.showSelfRatingError = true;
            }
            else {
                state.showRating = true;
            }
        }
        function redirectAndRateLater() {
            window.location.href = '/login?redirect=' + encodeURIComponent(props.rateLink);
        }
        function handleRateNowLink() {
            if (props.isLoggedIn) {
                tryToShowRatingModal();
                return;
            }
            redirectAndRateLater();
        }
        function splitReviews() {
            const result = [];
            const left = [];
            const right = [];
            state.visibleReviews.map((record, index) => {
                (index + 1) % 2 ? left.push(record) : right.push(record);
            });
            result.push(left);
            result.push(right);
            return result;
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (__props.enableRedesign)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                    : _createCommentVNode("", true),
                _createElementVNode("section", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("h5", _hoisted_4, _toDisplayString(__props.t.title), 1)
                    ]),
                    (state.reviews.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                                (state.ratingScoreFormatted >= 3)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                        _createVNode(_unref(StarFilledSvg)),
                                        _createTextVNode(" " + _toDisplayString(state.ratingScoreFormatted) + " ", 1),
                                        _cache[3] || (_cache[3] = _createElementVNode("span", null, "·", -1)),
                                        _createElementVNode("strong", null, _toDisplayString(state.numRatesFormatted) + " " + _toDisplayString(__props.t.ratings) + " | " + _toDisplayString(state.numReviewsFormatted) + " " + _toDisplayString(__props.t.reviews), 1)
                                    ]))
                                    : _createCommentVNode("", true),
                                (__props.editMode)
                                    ? (_openBlock(), _createBlock(CopyableText, {
                                        key: 1,
                                        href: "#"
                                    }, {
                                        default: _withCtx(({ copyTextToClipboard }) => [
                                            _createElementVNode("strong", null, [
                                                _createElementVNode("a", {
                                                    href: "javascript:void(0)",
                                                    class: "f-inter",
                                                    onClick: _withModifiers(($event) => (copyTextToClipboard(__props.rateLink)), ["prevent"])
                                                }, _toDisplayString(__props.t.copyRatingLink), 9, _hoisted_8)
                                            ])
                                        ]),
                                        copied: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(__props.t.copied), 1)
                                        ]),
                                        _: 1
                                    }))
                                    : (_openBlock(), _createElementBlock("a", {
                                        key: 2,
                                        href: "javascript:void(0)",
                                        class: "f-inter z-b4",
                                        onClick: handleRateNowLink
                                    }, [
                                        _createElementVNode("b", null, _toDisplayString(__props.t.rateNow), 1)
                                    ]))
                            ]),
                            (state.showSelfRatingError)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(__props.t.youCannotRateYourself), 1))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_10, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.visibleReviews, (reviewItem) => {
                                    return (_openBlock(), _createBlock(Review, {
                                        key: String(reviewItem.pid) + '-' + String(reviewItem.created),
                                        class: "review-list__item",
                                        review: reviewItem,
                                        t: __props.t
                                    }, null, 8, ["review", "t"]));
                                }), 128))
                            ]),
                            (state.reviews.length > state.visibleRecords)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                    _createElementVNode("a", {
                                        class: "f-inter",
                                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (state.visibleRecords += 6), ["prevent"]))
                                    }, _toDisplayString(__props.t.showMore), 1)
                                ]))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.visibleReviewsSplit[0], (reviewItem) => {
                                        return (_openBlock(), _createBlock(Review, {
                                            key: String(reviewItem.pid) + '-' + String(reviewItem.created),
                                            class: "review-list__item",
                                            review: reviewItem,
                                            t: __props.t
                                        }, null, 8, ["review", "t"]));
                                    }), 128))
                                ]),
                                _createElementVNode("div", _hoisted_14, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.visibleReviewsSplit[1], (reviewItem) => {
                                        return (_openBlock(), _createBlock(Review, {
                                            key: String(reviewItem.pid) + '-' + String(reviewItem.created),
                                            class: "review-list__item",
                                            review: reviewItem,
                                            t: __props.t
                                        }, null, 8, ["review", "t"]));
                                    }), 128))
                                ])
                            ]),
                            (state.reviews.length > state.visibleRecords)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createElementVNode("a", {
                                        class: "f-inter",
                                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (state.visibleRecords += 6), ["prevent"]))
                                    }, _toDisplayString(__props.t.showMore), 1)
                                ]))
                                : _createCommentVNode("", true)
                        ]))
                        : (__props.editMode && !state.reviews.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                    _createTextVNode(_toDisplayString(__props.t.askForSomeLove) + " ", 1),
                                    _createVNode(CopyableText, null, {
                                        default: _withCtx(({ copyTextToClipboard }) => [
                                            _createElementVNode("a", {
                                                class: "f-inter",
                                                onClick: ($event) => (copyTextToClipboard(__props.rateLink))
                                            }, _toDisplayString(__props.t.copyRatingLink), 9, _hoisted_18)
                                        ]),
                                        copied: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(__props.t.copied), 1)
                                        ]),
                                        _: 1
                                    })
                                ])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                    (state.ratingScoreFormatted >= 3)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                            _createVNode(_unref(StarFilledSvg)),
                                            _createTextVNode(" " + _toDisplayString(state.ratingScoreFormatted) + " ", 1),
                                            _cache[4] || (_cache[4] = _createElementVNode("span", null, "·", -1)),
                                            _createElementVNode("strong", null, _toDisplayString(state.numRatesFormatted) + " " + _toDisplayString(__props.t.ratings) + " | " + _toDisplayString(state.numReviewsFormatted) + " " + _toDisplayString(__props.t.reviews), 1)
                                        ]))
                                        : _createCommentVNode("", true),
                                    _createTextVNode(" " + _toDisplayString(__props.t.beFirstOneShowLove) + " ", 1),
                                    _createElementVNode("a", {
                                        href: "#",
                                        class: "f-inter",
                                        onClick: _withModifiers(handleRateNowLink, ["prevent"])
                                    }, _toDisplayString(__props.t.rateNow), 1),
                                    (state.showSelfRatingError)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(__props.t.youCannotRateYourself), 1))
                                        : _createCommentVNode("", true)
                                ])
                            ])),
                    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
                        _createVNode(RateInstructorHandler, {
                            show: state.showRating,
                            "onUpdate:show": _cache[2] || (_cache[2] = ($event) => ((state.showRating) = $event)),
                            mode: "rate",
                            t: __props.t,
                            "is-logged-in": __props.isLoggedIn,
                            "rating-submitted": __props.instructorRatingSubmitted,
                            rating: __props.studentInstructorRating,
                            "rate-link": __props.rateLink,
                            "rate-instructor-action": __props.rateInstructorAction,
                            "instructor-name": __props.instructorName,
                            "instructor-pid": __props.instructorPid,
                            "onRateInstructor:reviewsUpdated": updateRatingsAndReviews
                        }, null, 8, ["show", "t", "is-logged-in", "rating-submitted", "rating", "rate-link", "rate-instructor-action", "instructor-name", "instructor-pid"])
                    ]))
                ])
            ], 64));
        };
    }
});
