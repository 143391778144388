import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "item-container" };
const _hoisted_2 = { class: "item-heading" };
const _hoisted_3 = { class: "z-b2 f-inter item-title" };
const _hoisted_4 = { class: "z-b4 f-inter item-subtitle" };
const _hoisted_5 = { class: "item" };
const _hoisted_6 = {
    key: 0,
    class: "z-b4 f-inter"
};
const _hoisted_7 = { class: "item-content" };
const _hoisted_8 = { class: "z-b5 f-inter" };
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { key: 1 };
const _hoisted_11 = { class: "item-types-wrapper" };
const _hoisted_12 = { class: "item-lists" };
const _hoisted_13 = { key: 1 };
const _hoisted_14 = { class: "item-lists" };
const _hoisted_15 = { class: "z-b4 f-inter item-type" };
const _hoisted_16 = { class: "item-type" };
const _hoisted_17 = {
    key: 1,
    class: "item-types-show-more-link"
};
const _hoisted_18 = { class: "item-cta" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ZButton = _resolveComponent("ZButton");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.data.class_quantity) + " " + _toDisplayString(_ctx.t.classes), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.subtitle), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
            (_ctx.data.description)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.data.description), 1))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.t.applies) + " ", 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.content_types, (contentType, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: contentType,
                            class: "z-b5 f-inter"
                        }, [
                            _createTextVNode(_toDisplayString(contentType.title) + " ", 1),
                            (index < _ctx.data.content_types.length - 1)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_9, ", "))
                                : _createCommentVNode("", true),
                            (index === _ctx.data.content_types.length - 2)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.and), 1))
                                : _createCommentVNode("", true)
                        ]));
                    }), 128))
                ])
            ]),
            _createElementVNode("div", _hoisted_11, [
                (_ctx.allItemsDisplayed)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        ref: "itemTypesContainerRef",
                        class: _normalizeClass(["item-types-container", { expanded: _ctx.showMore }])
                    }, [
                        _createElementVNode("ul", _hoisted_12, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSpecialties, (specialty) => {
                                return (_openBlock(), _createElementBlock("li", {
                                    key: specialty.slug,
                                    class: "z-b4 f-inter item-type"
                                }, [
                                    _createElementVNode("b", null, _toDisplayString(specialty.name), 1)
                                ]));
                            }), 128))
                        ])
                    ], 2))
                    : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("ul", _hoisted_14, [
                            _createElementVNode("li", _hoisted_15, [
                                _createElementVNode("b", _hoisted_16, _toDisplayString(_ctx.t.allFitnessTypes), 1)
                            ])
                        ])
                    ]))
            ]),
            (_ctx.showMoreLink)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("a", {
                        href: "#",
                        class: "z-b4 f-inter",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.showMore = !_ctx.showMore), ["prevent"]))
                    }, _toDisplayString(_ctx.showMore ? _ctx.t.seeLess : _ctx.t.seeAll), 1)
                ]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_ZButton, {
                    variety: "rogue",
                    href: _ctx.data.url,
                    wide: "never"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t.buy), 1)
                    ]),
                    _: 1
                }, 8, ["href"])
            ])
        ])
    ]));
}
