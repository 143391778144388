import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", null, [
        ((_ctx.allowFlash && !_ctx.displayCopied) || !_ctx.allowFlash)
            ? _renderSlot(_ctx.$slots, "default", {
                key: 0,
                copyTextToClipboard: _ctx.copyTextToClipboard,
                displayCopied: _ctx.displayCopied
            })
            : _createCommentVNode("", true),
        (_ctx.allowFlash && _ctx.displayCopied)
            ? _renderSlot(_ctx.$slots, "copied", { key: 1 })
            : _createCommentVNode("", true)
    ]));
}
