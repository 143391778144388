import { defineComponent, ref, computed, onMounted } from 'vue';
import ZButton from '@components/Core/ZButton.vue';
import { validateTranslations } from "@ts/Util/i18n";
const translationDefaults = {
    buy: "Buy",
    classes: 'Classes',
    applies: 'Applies to',
    allFitnessTypes: 'All Fitness Types',
    seeAll: 'See all class types',
    seeLess: 'See less class types'
};
export default defineComponent({
    name: 'ClassPacksItem',
    components: {
        ZButton
    },
    props: {
        t: {
            type: Object,
            default: () => translationDefaults,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        data: {
            type: Object,
            required: true
        },
        subtitle: {
            type: String,
            default: ''
        },
        maxDisplayed: {
            type: Number,
            default: 19
        },
        and: {
            type: String,
            default: 'and',
        }
    },
    setup(props) {
        const itemTypesContainerRef = ref(null);
        const showMoreLink = ref(false);
        const showMore = ref(false);
        const filteredSpecialties = computed(() => {
            const uniqueCombinations = [];
            props.data.fitness_specialties.forEach((item) => {
                const { slug, name } = item;
                if (!uniqueCombinations.find((combination) => combination.slug === slug &&
                    combination.name === name)) {
                    uniqueCombinations.push({ slug, name });
                }
            });
            return uniqueCombinations;
        });
        const allItemsDisplayed = computed(() => {
            return props.maxDisplayed >= props.data.fitness_specialties.length;
        });
        onMounted(() => {
            if (itemTypesContainerRef.value) {
                const scrollHeight = itemTypesContainerRef.value.scrollHeight;
                const clientHeight = itemTypesContainerRef.value.clientHeight;
                if (scrollHeight > clientHeight) {
                    showMoreLink.value = true;
                }
            }
        });
        return {
            itemTypesContainerRef,
            showMoreLink,
            showMore,
            allItemsDisplayed,
            filteredSpecialties
        };
    }
});
