import { defineComponent, computed, ref } from 'vue';
import ClassFilterModal from '@components/ClassListing/ClassFilterModal.vue';
import VODItem from './VODItem.vue';
import FilterSvg from '@bx-icons/regular/bx-filter.svg';
import PencilIcon from '@bx-icons/regular/bx-pencil.svg';
import ZButton from "@components/Core/ZButton.vue";
export default defineComponent({
    components: {
        VODItem,
        FilterSvg,
        PencilIcon,
        ClassFilterModal,
        ZButton
    },
    props: {
        t: {
            type: Object,
            default: () => ({
                title: '',
            })
        },
        editLink: {
            type: String,
            default: () => ''
        },
        addLink: {
            type: String,
            default: () => ''
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        specialties: {
            type: Object,
            default: () => { }
        },
        hideFilters: {
            type: Boolean,
            default: false
        },
        pageSize: {
            type: Number,
            default: 6
        },
        videos: {
            type: Array,
            default: () => []
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        memberView: {
            type: Boolean,
            default: false
        },
    },
    setup(props) {
        const showFilters = ref(false);
        const selectedFilters = ref({
            durations: [],
            specialties: [],
        });
        let durationsFilter = [], specialtiesFilter = [];
        props.videos.forEach((video) => {
            let duration;
            let video_duration = parseInt(video.video_duration);
            specialtiesFilter = specialtiesFilter.concat(video.fitness_specialties);
            if (video_duration < 1860) {
                duration = 'underThirty';
            }
            else if (video_duration <= 3600) {
                duration = 'thirtySixty';
            }
            else {
                duration = 'overSixty';
            }
            if (!durationsFilter.includes(duration)) {
                durationsFilter.push(duration);
            }
        });
        const availableFilters = ref({
            durations: durationsFilter,
            specialties: [...new Set(specialtiesFilter)],
        });
        const filteredClasses = computed(() => {
            let { durations, specialties } = selectedFilters.value;
            return props.videos.filter((video) => {
                if (durations.length &&
                    durations.every((d) => d.min > video.video_duration || d.max < video.video_duration)) {
                    return false;
                }
                if (specialties.length &&
                    video.fitness_specialties.every(s => !specialties.includes(s))) {
                    return false;
                }
                return true;
            });
        });
        const visibleRecords = ref(props.pageSize);
        const visibleVideos = computed(() => {
            return filteredClasses.value.slice(0, visibleRecords.value);
        });
        const filterCount = computed(() => {
            let { durations, specialties } = selectedFilters.value;
            return durations.length + specialties.length;
        });
        const clearFilters = () => {
            selectedFilters.value = {
                durations: [],
                specialties: [],
            };
        };
        return {
            filteredClasses,
            selectedFilters,
            availableFilters,
            showFilters,
            visibleRecords,
            visibleVideos,
            filterCount,
            clearFilters,
        };
    }
});
