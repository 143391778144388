import { defineComponent } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import { formatCurrencyStr } from '@ts/Util/currency';
import { interpolate } from '@ts/Util/text';
import Carousel from '@components/Util/Carousel.vue';
import { default as ClassPacksItem } from "@components/ClassPackages/ClassPackItem.vue";
import EditIcon from '@bx-icons/regular/bx-pencil.svg';
const translationDefaults = {
    title: "Class packs",
    each: "each",
    buy: "Buy",
    noClassPacksPosted: 'no Class Packs Posted.',
    add: 'Add'
};
export default defineComponent({
    name: 'ClassPacks',
    components: {
        ClassPacksItem,
        Carousel,
        EditIcon
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        title: {
            type: String,
            default: '',
        },
        packs: {
            type: Array,
            default: () => [],
            required: false
        },
        subtitle: {
            type: String,
            default: ''
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        classPacksAction: {
            type: String,
            default: '',
        },
        and: {
            type: String,
            default: 'and',
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        memberView: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        packageSubtitle(price, class_quantity, price_currency) {
            return interpolate('{0} ({1} {2})', [
                formatCurrencyStr(price, price_currency),
                formatCurrencyStr(price / class_quantity, price_currency),
                this.t.each
            ]);
        },
        checkBrowser() {
            return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        },
    },
});
